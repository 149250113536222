function initCalculator() {
    const dpiForm = document.querySelector('.dpi')
    if (dpiForm) {
        dpiForm.addEventListener('submit', function (e) {
            e.preventDefault()
            const myFormData = new FormData(e.target)
            const data = Object.fromEntries(myFormData.entries())
            const { new_dpi, current_dpi, current_sens } = data
            if (!new_dpi || !current_dpi || !current_sens) {
                alert('Please Fill All Required Fields')
                return
            }
            const newSens =
                (parseInt(current_dpi) * parseFloat(current_sens)) /
                parseInt(new_dpi)
            dpiForm.querySelector('input[name=new_sens]').value = newSens
        })
    }
}

export default initCalculator

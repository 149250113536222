import { ACTIVE_CLASSNAME } from '../constants'

const TOGGLER_ID = 'burger'
const MENU_CLASS = '.menu-collapsed'

function initBurgerMenu() {
    const burger = document.getElementById(TOGGLER_ID)
    const menu = document.querySelector(MENU_CLASS)

    function toggleMenu() {
        menu.classList.toggle(ACTIVE_CLASSNAME)
        burger.classList.toggle(ACTIVE_CLASSNAME)
    }

    menu.addEventListener('click', toggleMenu)

    burger.addEventListener('click', toggleMenu)
}

export default initBurgerMenu

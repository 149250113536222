import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/swiper-bundle.min.css'

const initSwiper = () => {
    const imagesContainers = document.querySelectorAll('.content__images') ?? []
    const fewImagesContainer = []
    imagesContainers.forEach((container, i) => {
        if (container.querySelectorAll('.content__image')?.length > 1) {
            container.classList.add(`swiper-${i}`)
            fewImagesContainer.push(container)
        }
    })

    fewImagesContainer.forEach((imagesContainer) => {
        console.log(imagesContainer.classList)
        new Swiper(`.${imagesContainer.classList[2]}`, {
            direction: 'horizontal',
            loop: true,
            centeredSlides: true,
            slidesPerView: 1,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    })
}

export default initSwiper

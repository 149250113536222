const mainPageRoutes = ['/', '/index.html']

function vkWidget() {
    if (mainPageRoutes.includes(window.location.pathname) && window.VK) {
        VK.Widgets.Poll(
            'vk_poll_-104832669_298335534',
            {},
            '298335534_ef4dc8bfcdf8638770'
        )
        VK.Widgets.Group('vk_groups', { mode: 3 }, 104832669)
    }
}

export default vkWidget

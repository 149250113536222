import { NAVLINK_CLASSNAME, ACTIVE_CLASSNAME } from '../constants'

function highlightActiveRoute() {
    const links = document.querySelectorAll(NAVLINK_CLASSNAME)
    links.forEach((link) => {
        link.classList.remove(ACTIVE_CLASSNAME)
        if (link.href.endsWith(window.location.pathname)) {
            link.classList.add(ACTIVE_CLASSNAME)
        }
    })
}

export default highlightActiveRoute

const HeaderStyle = document.querySelector('.header__sticky').style
const WrapperHeight = document.querySelector('.header').offsetHeight
const MenuHeight = document.querySelector('.header__nav').offsetHeight

function handleStickyHeader() {
    const scrollY = window.scrollY
    const heightDiff = WrapperHeight - MenuHeight
    HeaderStyle.transform =
        scrollY > heightDiff ? 'translateY(0)' : 'translateY(-100%)'
}

function initStickyHeader() {
    window.addEventListener('scroll', handleStickyHeader)
}

export default initStickyHeader

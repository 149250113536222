import initBurgerMenu from './modules/burger'
import initCalculator from './modules/dpicalc'
import highlightActiveRoute from './modules/highlightActiveRoute'
import initStickyHeader from './modules/stickyHeader'
import initRouting from './modules/routing'
import vkWidget from './modules/vkWidget'
import initSwiper from './modules/initSwiper'

document.addEventListener('DOMContentLoaded', () => {
    highlightActiveRoute()
    initBurgerMenu()
    initCalculator()
    initStickyHeader()
    initRouting()
    vkWidget()
    initSwiper()
})
